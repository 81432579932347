import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import imageMap from '../images/map.png'
import imgHeaderImage from '../images/top_contact.jpg'

const IndexPage = () => {


  return (
    <Layout pageTitle="Contact" headerImage={imgHeaderImage}>
      <SEO title="Contact"/>
      <div>
        <h2 className="section-title">How to Find Us</h2>
      </div>
      <div className="new-row">
        <div className="two-col-1 slim">
          <p className="general-text">Pipers dental practice has entrances in both Station Road East and Ellice Road Car Park.</p>
          <p className="general-text">The practice is 100 metres to the right from the east exit of the railway station. There is a large free standing pavement sign. Please use the entry phone next to the Beauty Shop 'Beauty Box'.</p>
          <p className="general-text">There is a municipal car park to the rear of the building from Ellice Road, and from there direct access to our back door. There is free parking for up to 1 hour, but please display a ticket in your car.</p>

          <div className="white-block-container">
            <div className="white-block">
              <span className="green">Your Primary Care Support is</span>
              <span className="address">
				<br/>Primary Care Support Surrey &amp; Sussex Area Team<br/>
                Cedar Court<br/>
				Guildford Road<br/>
				Leatherhead Surrey KT22 9AE<br/>
				Tel: 01372 201700</span>
            </div>
          </div>
        </div>
        <div className="two-col-2 fat">
          <div className="map">
            <a href="http://g.co/maps/pk7ww" title="Open in Google Maps" target="_blank" rel="noreferrer">Open in Google Maps</a>
            <img src={imageMap} className="map" title="Map of local area" alt="Map of local area"/>
          </div>
        </div>
      </div>
    </Layout>
  )
}



export default IndexPage
